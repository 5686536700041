import { Button } from "@mui/material";
import { display } from "@mui/system";
import React from "react";
import splashScreen from "../Media/folktopia_profile_picture.webp";
import { useNavigate } from "react-router-dom"

export default function SplashScreen() {
    const navigate = useNavigate();

  return (
    <section
      style={{
        marginTop: "140px",
      }}
    >
      <div
        className="register"
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "978px",
          width: "100vw",
          height: "50.5vh",
          display: "flex",
          border: "2px solid #f24822",
          borderRadius: "5px",
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        <div
          className="col-1"
          style={{
            margin: "auto",
            padding: "3em 0",
            flexDirection: "column",
            display: "flex",
            width: "50%",
            alignItems: "center",
          }}
        >
          <h3 style={{ color: "#000000", alignItems: "center" }}>
            CREATE YOUR ACCOUNT TODAY
          </h3>
          <br></br>
          <p style={{}}>
            With an account you will be able to access more<br></br> content and
            contribute interviews to the StoryCorps <br></br>collection.
          </p>

          <Button
            onClick={() => {
                navigate("/signUp")
            }}
            style={{
              backgroundColor: "#f24822",
              color: "#FFFFFF",
              fontSize: "1em",
              cursor: "pointer",
              width: "50%",
            }}
          >
            Create Your Account
          </Button>
          <br></br>
          <h5>
            Already have an account?{" "}
            <a href="/signin" style={{ color: "#f24822" }}>
              Log In
            </a>
          </h5>
        </div>

        <div
          className="col-2"
          style={{ flexDirection: "column", display: "flex", width: "50%" }}
        >
          <img
            src={splashScreen}
            alt="Image not Found"
            style={{
              width: "auto",
              height: "auto",
              marginRight: "-1px",
              objectFit: "cover",
              alignSelf: "flexEend",
              borderRadius: "5px",
            }}
          />
        </div>
      </div>
    </section>
  );
}
