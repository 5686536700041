import { Box, TextField, Button, Link } from "@mui/material";
import { React, useState } from "react";
import { Email, Lock } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import Folktopia from "../Media/Folktopia.png";
import { signup } from "../auth/helper/index";
import { useNavigate } from "react-router-dom";
export default function Signup() {
  const [valid, setValid] = useState(true);

  const [passToggle, setPassToggle] = useState(true);

  const [confirmPassword, setConfirmPassword] = useState(true);

  const [inputConfirmPass, set] = useState("");

  const navigate = useNavigate();

  // signup data
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    error: "",
    success: false,
  });

  const { firstName, lastName, email, password, error, success } = values;

  // to update values state
  const handleChange = (name) => (event) => {
    setValues({ ...values, error: false, [name]: event.target.value });
  };

  // Submit Button Function
  const onSubmit = (event) => {
    if (email !== "" && password !== "") {
      event.preventDefault(); // It will prevent the form from submitting itself so that we could do our regular stuff

      console.log(firstName, lastName, email, password); // to check the form submission

      signup({ firstName, lastName, email, password }) // It will automatically fires the request to the backend and return the respond & the error
        .then((data) => {
          // data is the response we're getting from signup function - backend
          if (data.error) {
            // checking if that response is the error or a valid data
            // it is the error then
            setValues({ ...values, error: data.error, success: false });
          } // if there was a success
          else {
            setValues({
              ...values,
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              error: "",
              success: true,
            });
            alert("New account created successfully!");
            navigate("/signin");
          }
        })
        .catch((error) => {
          console.log("Error in Signup");
        });
    }
    // alert("New account created successfully!");
    // navigate("/signin");
  };

  const successMessage = () => {
    return (
      <div
        className="alert alert-success"
        style={{ display: success ? "" : "none" }}
      >
        New account created successfully. Please <Link to="/">Login Here</Link>
      </div>
    );
  };

  const errorMessage = () => {
    return (
      <div
        className="alert alert-danger"
        style={{ display: error ? "" : "none" }}
      >
        {error}
      </div>
    );
  };

  const emailSx = {
    mr: 0.8,
    mt: 2.5,
    alignSelf: "start",
  };

  const flexStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    mb: 0.5,
  };

  const handleEmailValidation = (event) => {
    const emailRegex = /^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{3,}$/;
    setValid(false);
    if (emailRegex.test(event.target.value)) {
      setValid((value) => {
        return !valid;
      });
      setValues({ ...values, email: event.target.value });
    }
  };

  const handlePasswordToggle = () => {
    setPassToggle((value) => !passToggle);
  };

  const handleConfirmPassword = () => {
    // if ()
    // setConfirmPassword(value => !confirmPassword)
  };

  return (
    <div>
      {successMessage()}
      {errorMessage()}
      <Box
        component="div"
        sx={{
          height: "80vh",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          component="form"
          sx={{
            width: 500,
            height: 250,
            background: "white",
            borderRadius: "10px",
            border: "1px solid #dfdcdc",
            flex: 1,
            mt: "10ch",
            mb: "10ch",
            p: "1ch 10ch 1ch 10ch",
          }}
        >
          <Box
            component="img"
            src={Folktopia}
            sx={{ height: 200, width: 200, mt: "50px", mr: "4ch", ml: "4ch" }}
          ></Box>

          <Box sx={flexStyle}>
            <PersonIcon sx={{ ...emailSx }} />
            <TextField
              type="text"
              value={firstName}
              variant="standard"
              label="First Name"
              sx={{ width: "300px" }}
              onChange={handleChange("firstName")}
            />
          </Box>

          <Box sx={flexStyle}>
            <PersonIcon sx={{ ...emailSx }} />
            <TextField
              type="text"
              value={lastName}
              variant="standard"
              label="Last Name"
              sx={{ width: "300px" }}
              onChange={handleChange("lastName")}
            />
          </Box>

          <Box sx={flexStyle}>
            <Email sx={{ ...emailSx }} fontSize="small" />
            <TextField
              type="text"
              variant="standard"
              label="Email"
              sx={{ width: "300px" }}
              onChange={handleEmailValidation}
              helperText={valid ? "" : "Please Enter valid Email address"}
              error={valid ? false : true}
            />
          </Box>

          <Box sx={flexStyle}>
            <Lock sx={emailSx} fontSize="small" />
            <TextField
              type="password"
              value={password}
              variant="standard"
              label="Password"
              sx={{ width: 300 }}
              onChange={handleChange("password")}
            />
          </Box>

          {/* <Box sx={flexStyle}>
            <Lock sx={emailSx} fontSize="small" />
            <TextField
              type="password"
              variant="standard"
              label="Confirm Password"
              sx={{ width: 300 }}
              onBlur={handleConfirmPassword}
            />
          </Box> */}

          <Button
            variant="contained"
            sx={{
              backgroundColor: "#f24822",
              mt: 5,
              height: 48,
              width: "40ch",
            }}
            onClick={onSubmit}
          >
            Signup
          </Button>
        </Box>
      </Box>
    </div>
  );
}
