import React, { useEffect } from "react";
import { useState } from 'react'
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Data from "../DashboardData.json"
import img from "../logo.svg";

const Dashboard = () =>  
{
  const [Info ,setInfo] =  useState([]);

  const navigate = useNavigate();
  
  useEffect(() => {    
   fetch(`${process.env.REACT_APP_BACKEND}audio/get`, {
      method: 'GET'
    })
      .then((res) => res.json())
      .then((data) => setInfo(data))
      .catch((err) => console.error(err));
  
  },[]);
  return (
    <div>
      <Grid container spacing={2} padding={5}>
        {Info.map((item) => (
          <Grid item xl={4} lg={6} sm={8} xs={12}>
            <Card sx={{ height: "100%", border: "1px solid #f24822"}}
                onClick={ () => {
                  window.localStorage.setItem("cardToView", JSON.stringify(item));
                  navigate("/summaryPage");
                }}
                >
              <CardMedia
                component="img"
                height={350}
                sx={{objectFit: "stretched" }}
                image={item.imageUrl}
                alt="Picture of the Story"
              />
              <CardContent 
                >
                <Typography gutterBottom variant="h5" component="div">
                  {item.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Dashboard;