import * as React from "react";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import "../App.css";
import { userContext } from "../App";
import axios from "axios";
// import { Howl, Howler } from "howler";

import Card from "@mui/material/Card";
import CardReact from "react-bootstrap/Card";
import CardMedia from "@mui/material/CardMedia";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";

const SummaryPage = (props) => {
  const [isPlaying, setIsPlaying] = useState(false);

  function togglePlay() {
    const audioElement = document.getElementById("audio");
    if (isPlaying) {
      audioElement.pause();
      setIsPlaying(false);
    } else {
      audioElement.play();
      setIsPlaying(true);
    }
  }

  const Data = JSON.parse(window.localStorage.getItem("cardToView"));
  var mappedSpeakersArray = [];
  var mappedTags = [];

  const {
    imageUrl,
    summary,
    playVersionOfStory,
    storyVersionOfStory,
    settings,
    speakers,
    clothing,
    locations,
    events,
    characters,
    memories,
    tags,
    title,
    audioUrl,
    AudioLength,
  } = Data;

  const navigate = useNavigate();

  const [ImageUrl, setImageUrl] = useState(imageUrl);
  const [Summary, setSummary] = useState(summary);
  const [PlayVersionOfStory, setPlayVersionOfStory] =
    useState(playVersionOfStory);
  const [StoryVersionOfStory, setStoryVersionOfStory] =
    useState(storyVersionOfStory);
  const [Settings, setSettings] = useState(settings);
  const [Speakers, setSpeakers] = useState(speakers);
  const [Clothings, setClothings] = useState(clothing);
  const [Locations, setLocations] = useState(locations);
  const [Events, setEvents] = useState(events);
  const [Characters, setCharacters] = useState(characters);
  const [Memories, setMemories] = useState(memories);
  const [Tags, setTags] = useState(tags);
  const [Title, setTitle] = useState(title);
  const [AudioUrl, setAudioUrl] = useState(audioUrl);
  const [audioLength, setAudioLength] = useState(AudioLength);

  function expandSeconds(seconds) {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds - hours * 3600) / 60);
    let remainingSeconds = seconds - hours * 3600 - minutes * 60;

    return `${hours} hour(s), ${minutes} minute(s), ${remainingSeconds} second(s)`;
  }

  return (
    <div>
      <Box
        sx={{
          border: 1,
          borderColor: "primary.main",
          bgcolor: "#e0e0e0",
          height: "300px",
          mt: 2.5,
        }}
      >
        <audio id="audio" src={AudioUrl}></audio>
        <button
          style={{
            margin: 5,
            width: 100,
            height: 40,
            backgroundColor: "#f24822",
            border: "1px solid #f24822",
          }}
          className="btn-primary"
          onClick={togglePlay}
        >
          {isPlaying ? "Pause Sound" : "Play Sound"}
        </button>
        <br />
        <br />
        {/* Title */}
        <CardReact.Title
          style={{ fontSize: 50, fontFamily: "Roboto", fontWeight: "normal" }}
        >
          Title: {Title}
        </CardReact.Title>
        {/* Speaker */}
        <CardReact.Text>
          <em>
            Speakers:&nbsp;
            {(mappedSpeakersArray = Speakers.map((speaker) => {
              return speaker;
            })).join(", ")}
          </em>{" "}
          <br />
          Audio Length: {expandSeconds(audioLength)} long
        </CardReact.Text>
        <br />
        <Button variant="outline-danger" style={{ margin: 5 }}>
          <a href="#Read" style={{ textDecoration: "none", color: "white" }}>
            READ TRANSCRIPT
          </a>
        </Button>{" "}
      </Box>
      <Grid container spacing={2} padding={5}>
        <Grid item xl={4} lg={6} sm={8} xs={12}>
          <Card
            id="Read"
            sx={{ height: "150px", width: "150px", objectFit: "contain" }}
          >
            <CardMedia
              component="img"
              height="350"
              image={ImageUrl}
              alt="Picture of the product"
            />
          </Card>
        </Grid>
      </Grid>
      <Box sx={{ marginLeft: 5, marginRight: 5, marginBottom: 10}}>
        {/* Summary */}
        <CardReact.Title style={{ fontFamily: "Roboto", fontWeight: "normal" }}>
          Summary:{" "}
        </CardReact.Title>
        <CardReact.Text style={{ textAlign: "justify" }}>
          {Summary}
        </CardReact.Text>

        {/* Characters */}
        <CardReact.Title style={{ fontFamily: "Roboto", fontWeight: "normal" }}>
          Characters:{" "}
        </CardReact.Title>
        <CardReact.Text style={{ textAlign: "justify" }}>
          <ul>
            {Characters.map((character) => (
              <li>{character}</li>
            ))}
          </ul>
        </CardReact.Text>

        {/* Locations */}
        <CardReact.Title style={{ fontFamily: "Roboto", fontWeight: "normal" }}>
          Locations:{" "}
        </CardReact.Title>
        <CardReact.Text style={{ textAlign: "justify" }}>
          {Locations}
        </CardReact.Text>

        {/* Settings */}
        <CardReact.Title style={{ fontFamily: "Roboto", fontWeight: "normal" }}>
          Settings:{" "}
        </CardReact.Title>
        <CardReact.Text style={{ textAlign: "justify" }}>
          <ul>
            {Settings.map((setting) => (
              <li>{setting}</li>
            ))}
          </ul>
        </CardReact.Text>

        {/* Clothings */}
        <CardReact.Title style={{ fontFamily: "Roboto", fontWeight: "normal" }}>
          Clothings:{" "}
        </CardReact.Title>
        <CardReact.Text style={{ textAlign: "justify" }}>
          <ul>
            {Clothings.map((clothings) => (
              <li>{clothings}</li>
            ))}
          </ul>
        </CardReact.Text>

        {/* Events */}
        <CardReact.Title style={{ fontFamily: "Roboto", fontWeight: "normal" }}>
          Events:{" "}
        </CardReact.Title>
        <CardReact.Text style={{ textAlign: "justify" }}>
          {Events}
        </CardReact.Text>

        {/* Memories */}
        <CardReact.Title style={{ fontFamily: "Roboto", fontWeight: "normal" }}>
          Memories:{" "}
        </CardReact.Title>
        <CardReact.Text style={{ textAlign: "justify" }}>
          <ul>
            {Memories.map((memory) => (
              <li>{memory}</li>
            ))}
          </ul>
        </CardReact.Text>

        {/* Tags */}
        <CardReact.Title
          style={{
            fontFamily: "Roboto",
            fontWeight: "normal",
          }}
        >
          Tags:{" "}
        </CardReact.Title>
        <CardReact.Text style={{ textAlign: "justify" }}>
          <ul>
            {(mappedTags = Tags.map((tag) => {
              return tag;
            })).join(" | ")}
          </ul>
        </CardReact.Text>

        {/* Play Version */}
        <CardReact.Title style={{ fontFamily: "Roboto", fontWeight: "normal" }}>
          Play Version:{" "}
        </CardReact.Title>
        <CardReact.Text>
          {() => {
            PlayVersionOfStory.map((version) => {
              return version;
            });
          }}
        </CardReact.Text>

        {/* Story Summary */}
        <CardReact.Title style={{ fontFamily: "Roboto", fontWeight: "normal" }}>
          Story Summary (Story Version of Story){" "}
        </CardReact.Title>
        <CardReact.Text style={{ textAlign: "justify" }}>
          {StoryVersionOfStory}
        </CardReact.Text>
      </Box>
    </div>
  );
};

export default SummaryPage;