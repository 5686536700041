import createTheme from "@mui/material/styles/createTheme"


const theme = createTheme({
  palette: {
    mode: "dark",
    // primary: {
    //   light: '#ffa726',
    //   main: '#f57c00',
    //   dark: '#ef6c00',
    //   contrastText: 'rgba(0, 0, 0, 0.87)',
    // },
    // seconday: {
    //   main: "#d32f2f"
    // },
    // otherColor: {
    //   main: "#ffa726"
    // }
  }
})
export default theme