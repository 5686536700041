import React from "react";
import './App.css';
import Card from "./React-Project/Card"
import data from "./React-Project/CardArray"

import NFTCard from "./Components/NFTCard";
import Signin from "./Components/Signin"
import Signup from "./Components/Signup";

import Dashboard from "./Components/Dashboard";
import ForgotPassword from "./Components/ForgetPassword";
import Upload from "./Components/Upload";
import {Routes, Route} from "react-router-dom";
import { API } from './backend';
import Navbar from "./Components/Navbar";
import SummaryPage from "./Components/SummaryPage";
import SplashScreen from './Components/SplashScreen';

function App() 
{
const Element = [{id:1, rating: "5.0", reviewCount: 8, country: "pakistan",
title: "Life With jony jony", price: 16},
{id: 2, rating: "5.0", reviewCount: 8, country: "pakistan",
title: "Life With Honey Honey", price: 16},
{id:3, rating: "5.0", reviewCount: 8, country: "pakistan",
title: "Life With jony jony", price: 16}]

const ProfileDetails = [{id: 1, value: "Technology", techImg: "technology.png"},
{id: 2, value: "Description", techImg: "technology.png"},
{id: 3, value: "Supervisor", techImg: "technology.png"},
{id: 4, value: "Supervisor", techImg: "technology.png"}]

const ElementArray = Element.map(data => {
  return <Card
  key={data.id}
  // item={data}
  {...data}
   />
})

// const list = ProfileDetails.map(data => <Button>{data.value}</Button>)

const cardArray = data.map(items => {
  return <Card
  key={items.id}
  {...items}
   />
})

const NFTCardArray = []
for (var i=0; i<12; i++)
{
  NFTCardArray.push(<NFTCard />)
}

  return (
    
      <Routes>
        <Route path="/" element={<SplashScreen />}/>
        <Route path="/signin" element={<Signin />}/>
        <Route path="/signup" element={<Signup />} />
        <Route path="/dashboard" element={<><Navbar/><Dashboard/></>}/>
        <Route path="/summaryPage" element={<><Navbar/><SummaryPage /></>} />
        <Route path="/forgetPassword" element={<ForgotPassword />} />
        <Route path="/upload" element={<><Navbar role={"Student"}/><Upload/></>} />
      </Routes>
  );
}

export default App;