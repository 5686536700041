import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import Popover from "@mui/material/Popover";
import { Box, Divider, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import { motion } from "framer-motion";
import axios from 'axios';
const Upload = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [file, setFile] = React.useState(null);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUploadClick = () => {
    if (!file) {
      return;
    }
    var formData = new FormData();
    formData.append("audio", file);
    // 👇 Uploading the file using the fetch API to the server
    axios.post(`${process.env.REACT_APP_BACKEND}audio/upload`,
      formData,
      // 👇 Set headers manually for single file upload
      {headers: {
         'Content-Type': 'multipart/form-data'// 👈 Headers need to be a string
      },
    })
      .then((res) => res.json())
      .then((data) => console.log(data))
      .catch((err) => console.error(err));
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

    const handleFileChange = (e) => {
      if (e.target.files) {
      console.log(e.target.files[0])
      setFile(e.target.files[0]);
    }
  };


  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box sx={{ display: "flex", mt: "335px" }} justifyContent="center">
      <motion.div
        whileHover={{
          scale: 0.9,
          transition: { duration: 0.3 },
        }}
      >
        <Grid>
          <Paper
            elevation={10}
            sx={{
              alignContent: "center",
              height: "300px",
              width: "420px",
              borderRadius: "50px",
            }}
          >
            <Typography align="center" fontWeight={1000} pt={5}>
              Choose your files to upload
            </Typography>
            <Box>
              <Button
                variant="contained"
                component="label"
                sx={{
                  m: "50px 0px 95px 95px",
                  height: "9.6ch",
                  width: "25ch",
                  fontSize: "18px",
                  borderRadius: "10px",
                  backgroundColor: "#f24822",
                }}
              >
                <UploadFileOutlinedIcon fontSize="medium" sx={{ mr: "4px" }} />
                <input hidden accept="mp3/*" multiple type="file"
                onChange={handleFileChange}
                />
                Add Your File
              </Button>
              <Button
                variant="contained"
                component="label"
                 sx={{
                  m: "50px 0px 95px 95px",
                  height: "9.6ch",
                  width: "25ch",
                  fontSize: "18px",
                  borderRadius: "10px",
                  backgroundColor: "#f24822",
                  // color:'white',
                }}
              onClick={handleUploadClick}>Submit</Button>
            </Box>
          </Paper>
        </Grid>
      </motion.div>
    </Box>
  );
};

export default Upload;
