import { Box, TextField, Button, Link, Typography } from "@mui/material";
import { React, useState } from "react";
import { Email, Lock, Person } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles, styled } from "@mui/material/";

import theme from "../theme";
import { margin, positions, textAlign } from "@mui/system";

import { Link as RouterLink, useNavigate, Navigate } from "react-router-dom";
import { signin, authenticate, isAuthenticated } from "../auth/helper";
import Folktopia from "../Media/Folktopia.png";

export default function Signin() {
  const [roles, setRoles] = useState("Student");
  const [valid, setValid] = useState(true);
  const [values, setValues] = useState({
    role: 1,
    email: "",
    password: "",
    error: "",
    loading: false,
    didRedirect: false, // use to know if the user has been redirect to any other page, hence signed-in
  });

  const { role, email, password, error, loading, didRedirect } = values;

  const { user } = isAuthenticated();

  // to update values state
  const handleChange = (name) => (event) => {
    setValues({ ...values, error: false, [name]: event.target.value });
  };

  const handleEmailValidation = (event) => {
    const emailRegex = /^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{3,}$/;
    setValid(false);
    if (emailRegex.test(event.target.value)) {
      setValid((value) => {
        return !valid;
      });
      setValues({ ...values, email: event.target.value });
    }
  };

  const onSubmit = (event) => {
    // console.log(values);
    if (email !== "" && password !== "") {
      event.preventDefault();
      setValues({ ...values, error: false, loading: true });
      // console.log(values);
      signin({ email, password })
        .then((data) => {
          if (data.error) {
            // console.log("IN DATA.ERROR");
            setValues({ ...values, error: data.error, loading: false });
          } else {
            // console.log("NOT IN DATA.ERROR");
            authenticate(data, () => {
              setValues({ ...values, didRedirect: true });
              // console.log("ABOUT TO PERFORM_REDIRECT");
              performRedirect();
              loadingMessage();
              errorMessage();
            });
          }
        })
        .catch(console.log("Sign-in request failed"));
    }
    performRedirect();
  };

  const performRedirect = () => {
    // console.log("Performing redirect with didredirect" + didRedirect);
    
    if (didRedirect) {
        alert("you have been logged-in successfully!");
        navigate("/dashboard")
    }

      // alert("you have been logged-in successfully!");
      //   navigate("/dashboard")

    // if (isAuthenticated())
    // {
    //   return <Navigate to="/" />
    // }
  };

  const loadingMessage = () => {
    return (
      loading && (
        <div className="alert alert-info">
          <h2>Loading...</h2>
        </div>
      )
    );
  };

  const errorMessage = () => {
    return (
      <div
        className="alert alert-danger"
        style={{ display: error ? "" : "none" }}
      >
        {error}
      </div>
    );
  };

  const roleArray = ["Supervisor", "Student", "Admin"];

  const navigate = useNavigate();

  const [passToggle, setPassToggle] = useState(true);

  // const [inputEmail, setInputEmail] = useState("")

  const emailSx = {
    mr: 0.8,
    mt: 2.5,
    alignSelf: "start",
  };

  const flexStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  };

  const handleRole = (event) => {
    setRoles(event.target.value);
    if (event.target.value === "Admin") {
      setValues({ ...values, role: 0 });
    } else if (event.target.value === "Student") {
      setValues({ ...values, role: 1 });
    } else {
      setValues({ ...values, role: 2 });
    }
  };

  return (
    <Box
      component="div"
      sx={{
        height: "80vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        // position: "static"
      }}
    >
      <Box
        component="form"
        sx={{
          width: 500,
          height: 250,
          background: "white",
          borderRadius: "10px",
          flex: 1,
          mt: "10ch",
          mb: "10ch",
          p: "1ch 10ch 1ch 10ch",
          backgroundColor: "white",
          border: "1px solid #dfdcdc",
        }}
      >
        <Box
          component="img"
          src={Folktopia}
          sx={{ height: 200, width: 200, mt: "50px", mr: "4ch", ml: "4ch" }}
        ></Box>

        <Box sx={flexStyle}>
          <Email sx={{ ...emailSx }} fontSize="small" />
          <TextField
            type="text"
            variant="standard"
            label="Email"
            sx={{ width: "300px" }}
            onChange={handleEmailValidation}
            helperText={valid ? "" : "Please Enter valid Email address"}
            error={valid ? false : true}
          />
        </Box>

        <Box sx={{ ...flexStyle, mb: "0.6ch" }}>
          <Lock sx={emailSx} fontSize="small" />
          <TextField
            type="password"
            variant="standard"
            autoComplete="current-password"
            label="Password"
            sx={{ width: 300 }}
            onChange={handleChange("password")}
          />
        </Box>

        {/* <Link component={RouterLink} to="/ForgetPassword"
            sx={{pl: "21.4ch", cursor: "pointer",
             textAlign: "end"}}>
                Forget Password?
          </Link> */}

        <Button
          onClick={onSubmit}
          variant="contained"
          sx={{ backgroundColor: "#f24822", mt: 5, ml: 2, height: 48, width: "38ch" }}
        >
          Login
        </Button>

        <Typography component="p" sx={{ mt: 1.5, ml: 3.2, textAlign: "start" }}>
          Don’t have an account already?
          <Link
            component={RouterLink}
            to="/signUp"
            sx={{ color: "#f24822", cursor: "pointer", mb: 2, pl: 0.5 }}
          >
            SignUp
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}
